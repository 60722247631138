<template>
  <div ref="nodteDiv" class="noteSidenav">
    <a href="#" class="noteClosebtn" @click.prevent="handleClose()">&times;</a>
    <div>
      <h4 class="text-center">{{ courseName }}</h4>
      <p class="text-center">{{ className }}</p>

      <template v-if="!showEdit">
        <a class="btn btn-hover-primary text-white border-right mb-2 ml-2" @click="handleAddNote()"
          >+筆記</a
        >
        <div class="">
          <ul class="cs-table" data-style="11">
            <li class="table__head d-none d-xl-flex">
              <div class="table__head-td">時間戳記</div>
              <div class="table__head-td">筆記標題</div>
              <div class="table__head-td">更新時間</div>
              <div class="table__head-td">操作</div>
            </li>
            <li
              class="table__item"
              data-toggle="modal"
              data-target="#memberMsg"
              v-for="(item, index) in noteList"
              :key="item.id"
            >
              <div class="table__item-td table__item--noteTime">
                <a href="#" @click.prevent="changeCurrentTime(item.recordNoteTime)">
                  {{ item.recordNoteTime | secConvertTime }}
                </a>
              </div>
              <div class="table__item-td table__item--noteTitle">
                {{ item.recordNoteTitle }}
              </div>
              <div class="table__item-td table__item--modifyDate">
                {{ item.modifyDate | toTaipei | moment('YYYY/MM/DD HH:mm') }}
              </div>
              <div class="table__item-td table__item--action">
                <button class="btn btn-primary btn-sm mr-2" @click="handleAddNote(index)">
                  <i class="fa fa-pencil"></i>
                </button>
                <button class="btn btn-danger btn-sm" @click="handleDelNote(index)">
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </template>
      <template v-else>
        <el-form
          style="width: 100%;"
          :model="userNoteForm"
          label-position="top"
          :rules="userNoteRules"
          ref="userNoteForm"
          class="cs-form"
          data-style="2"
          @submit.prevent.native
        >
          <!-- 標題摘要 -->
          <div class="form-group ">
            <label class="col-12 col-form-label necessary" for="recordNoteTitle">
              標題摘要
            </label>
            <el-form-item prop="recordNoteTitle" class="col-12">
              <el-input
                :class="`el-input__input`"
                placeholder="標題摘要"
                type="text"
                id="recordNoteTitle"
                v-model="userNoteForm.recordNoteTitle"
              />
            </el-form-item>
          </div>

          <!-- 筆記內容 -->
          <div class="form-group ">
            <label class="col-12 col-form-label necessary" for="recordNoteTitle">
              筆記內容
            </label>
            <div class="col-12">
              <textarea
                v-model="userNoteForm.recordNoteContent"
                class="form-control"
                style="resize:none;height:400px;"
              >
              </textarea>
            </div>
            <!-- <vue-editor
              :class="'col-12'"
              v-model="userNoteForm.recordNoteContent"
              :editor-toolbar="customToolbar"
            /> -->
            <el-form-item prop="recordNoteContent" class="col-12"> </el-form-item>
          </div>
          <!-- 時間戳記 -->
          <div class="form-group col-12">
            <label> 時間戳記 : {{ userNoteForm.recordNoteTime | secConvertTime }} </label>
            <a class="btn btn-sm btn-primary ml-2" @click="changeRecordNoteTime()"
              >取得目前影片時間</a
            >
          </div>
          <!-- 送出按紐 -->
          <div class="form-group mb-6 mb-md-8 text-right">
            <button
              type="button"
              class="btn btn-outline-secondary btn-lg border-radius-l mr-2"
              @click="showEdit = false"
            >
              <span class="small">返回列表</span>
            </button>
            <button
              type="button"
              class="btn btn-hover-primary text-white btn-lg border-radius-l mr-2"
              @click="handleSaveNote"
            >
              <span class="small">儲存筆記</span>
            </button>
          </div>
        </el-form>
      </template>
    </div>
  </div>
</template>

<script>
// import { VueEditor } from 'vue2-editor';
import {
  GetRecordNotes,
  DeleteRecordNoteById,
  SaveRecordNoteById,
  GetRecordNoteById,
} from '@/api/MemberRecordApi';

export default {
  // 筆記清單、顯示筆記列表、
  props: ['code', 'showModel', 'course', 'classItem', 'videoTime'],
  // components: {
  //   VueEditor,
  // },
  data() {
    return {
      // 編輯器選項
      customToolbar: [
        ['bold', 'italic', 'underline'], // 粗體、斜體、底線
        [{ list: 'ordered' }, { list: 'bullet' }], // 清單
        [{ color: [] }, { background: [] }], //  字體顏色
        ['clean'], // 清除格式
      ],
      // 筆記表單
      userNoteForm: {
        id: 0, // int:編號,新增=0
        classCode: '', // 課程代碼
        recordNoteTitle: '', // string:標題(20字)
        recordNoteContent: '', // string:html內文(不限制)
        recordNoteTime: 0, // int:時間戳(秒數)
      },
      // 檢查輸入的值規則
      userNoteRules: {
        recordNoteTitle: [
          { required: true, message: '請輸入標題摘要', trigger: 'blur' },
          { max: 20, message: '標題摘要不能超過20個字' },
        ],
        recordNoteContent: [{ required: true, message: '請輸入筆記內容', trigger: 'blur' }],
      },
      // 筆記清單
      noteList: [],
      // 顯示筆記視窗 (props)
      nodeOpenList: this.showModel,
      // 課程名稱 (props)
      courseName: this.course,
      className: this.classItem,
      // 課程相關資訊 (props)
      classCode: this.code,
      // 目前影片時間 (props)
      videoCurrentTime: this.videoTime,
      // 編輯視窗
      showEdit: false,
      // 編輯中的筆記index
      modifyNoteIndex: -1,
    };
  },
  methods: {
    // 關閉筆記列表
    handleClose() {
      this.showEdit = false;
      this.$emit('update:showModel', 0);
    },
    // 新增筆記
    handleAddNote(index = -1) {
      this.modifyNoteIndex = -1;
      this.userNoteForm.id = 0;
      this.userNoteForm.classCode = this.classCode;
      this.userNoteForm.recordNoteTitle = '';
      this.userNoteForm.recordNoteContent = '';
      this.userNoteForm.recordNoteTime = this.videoCurrentTime;
      if (index >= 0) {
        this.handleGetRecordNote(this.noteList[index].id);
        this.modifyNoteIndex = index;
      } else {
        this.$emit('pauseVoide');
      }
      this.showEdit = true;
    },
    // 刪除筆記
    handleDelNote(index) {
      const { id, recordNoteTitle } = this.noteList[index];
      this.$confirm(`確定要將[${recordNoteTitle}]刪除 ?`, '提示', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          DeleteRecordNoteById({ id }).then((response) => {
            if (response.data.statusCode === 0) {
              this.$message({
                showClose: true,
                type: 'success',
                message: '成功刪除筆記',
              });
              this.noteList.splice(index, 1);
              this.$emit('changeNoetCount', this.noteList.length);
            } else this.$router.replace('/error').catch();
          });
        })
        .catch(() => {});
    },
    // 儲存筆記
    handleSaveNote() {
      this.$refs.userNoteForm.validate((valid) => {
        if (valid) {
          SaveRecordNoteById(this.userNoteForm).then((response) => {
            if (response.data.statusCode === 0) {
              const msg = `成功${this.userNoteForm.id === 0 ? '新增' : '修改'}筆記`;
              if (this.userNoteForm.id === 0) {
                this.noteList.push({ ...response.data.data });
                this.userNoteForm.id = response.data.data.id;
                this.modifyNoteIndex = this.noteList.length - 1;
              } else {
                this.noteList.splice(this.modifyNoteIndex, 1, { ...response.data.data });
              }
              this.noteList = this._.sortBy(this.noteList, ['sort']);
              // this.noteList.sort((a, b) => a.sort > b.sort);
              this.$message({
                showClose: true,
                type: 'success',
                message: msg,
              });
              this.$emit('changeNoetCount', this.noteList.length);
            }
          });
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: '請將資料填寫完整',
          });
        }
      });
    },
    // 取得筆記列表
    async handleGetRecordNotes() {
      const { classCode } = this;
      const postData = {
        pageIndex: 1,
        pageSize: 9999,
        data: {
          classCode,
        },
      };
      await GetRecordNotes(postData).then((response) => {
        if (response.data.statusCode === 0) {
          const { data } = response.data;
          this.noteList = data;
        }
      });
    },
    // 取得筆記 by id
    handleGetRecordNote(id) {
      GetRecordNoteById({ id }).then((response) => {
        if (response.data.statusCode === 0) {
          this.userNoteForm = { ...response.data.data };
        }
      });
    },
    // 改變目前影片時間
    changeCurrentTime(time) {
      this.$emit('changeCurrentTime', time);
    },
    // 改變時間戳記
    changeRecordNoteTime() {
      this.$confirm('確定要將時間戳記，改為目前影片時間 ?', '提示', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.userNoteForm.recordNoteTime = this.videoCurrentTime;
        })
        .catch(() => {});
    },
  },
  watch: {
    code(val) {
      if (val !== '') {
        this.classCode = val;
      }
    },
    course(val) {
      this.courseName = val;
    },
    classItem(val) {
      this.className = val;
    },
    showModel(val) {
      this.nodeOpenList = val;
    },
    async nodeOpenList(val) {
      if (val > 0) {
        if (this.className === '') {
          this.$message({
            showClose: true,
            type: 'warning',
            message: '請先選擇課堂',
          });
          this.$emit('update:showModel', 0);
        } else {
          await this.handleGetRecordNotes();
          this.$refs.nodteDiv.classList.add('noteSidenavOpen');
          if (val > 1) this.handleAddNote();
          else this.showEdit = false;
        }
      } else {
        this.$refs.nodteDiv.classList.remove('noteSidenavOpen');
        this.showEdit = false;
      }
    },
    videoTime(val) {
      this.videoCurrentTime = val;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .ql-toolbar {
  background: white;
}
/deep/ .ql-editor {
  background: white;
}
</style>
