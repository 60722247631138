import baseAxios from '@/api/base';

baseAxios.defaults.baseURL = `${process.env.VUE_APP_APIPATH}`;

/** MemberRecord api */
/**
 * 加入我的最愛
 * @param {object} data { Name:課程代碼 }
 */
export const AddToFavorite = function AddToFavorite(data) {
  baseAxios.defaults.showLoading = false;
  return baseAxios.post('FrontEnd/MemberRecord/AddToFavorite', data);
};

/**
 * 取消我的最愛
 * @param {object} data { Name:課程代碼 }
 */
export const RemoveToFavorite = function RemoveToFavorite(data) {
  baseAxios.defaults.showLoading = false;
  return baseAxios.post('FrontEnd/MemberRecord/RemoveToFavorite', data);
};

/**
 * 取得筆記列表
 * @param {object} data
  {
    "pageIndex": 0,
    "pageSize": 0,
    "data": {
      "classCode": "string"
    }
  }
 */
export const GetRecordNotes = (data) => baseAxios.post('FrontEnd/MemberRecord/GetRecordNotes', data);

/**
 * 刪除筆記 BY id
 * @param {object} data { id }
 */
export const DeleteRecordNoteById = (data) => baseAxios.post('FrontEnd/MemberRecord/DeleteRecordNoteById', data);

/**
 * 儲存筆記 BY id
 * @param {object} data { id }
 */
export const SaveRecordNoteById = (data) => baseAxios.post('FrontEnd/MemberRecord/SaveRecordNoteById', data);

/**
 * 取得筆記 BY id
 * @param {object} data { id }
 */
export const GetRecordNoteById = (data) => baseAxios.post('FrontEnd/MemberRecord/GetRecordNoteById', data);
